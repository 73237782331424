<template>
<div>
  <v-container
    fill-height
    fluid
    grid-list-xl
  >
    <v-layout
      justify-center
      wrap
    >
    <v-flex md12>
      <v-alert
        :value="mappingStatus"
        :type="mappingStatusType"
      >
        Последненяя синхронизация: <span v-if="mappingStatus">{{ mappingStatus.time | date }} {{ mappingStatus.time | time }} ({{ mappingStatus.status }})</span>
        <span v-else>Загружается...</span>
         <v-btn dark @click="getVendingMachineStatus">Обновить</v-btn>
      </v-alert>
    </v-flex>
      <v-flex
        md12
      >
        <material-card
          color="green"
          :title="machine.name"
          :text="machine.serialVendingNumber"
        >
          <v-data-table
            :headers="headers"
            :items="items"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-success text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <td>{{ item.name }}</td>
              <td>{{ item.requiredAmount }}</td>
              <td><b style="font-size: 14px">{{ item.currentAmount }}</b> / <span style="font-size: 12px">{{ item.capacity }}</span></td>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>
      
    </v-layout>
  </v-container>
  <v-container fluid>
      <v-layout row wrap>
          <v-btn title="Ячейки" color="primary" :to="{ name: 'Торговый автомат', params: { id: id }}">Назад</v-btn>
      </v-layout>
    </v-container>

  </div>
</template>

<script>
export default {
  data: () => ({
    machine: {},
    headers: [
      {
        sortable: true,
        text: 'Товар',
        value: 'name'
      },
      {
        sortable: true,
        text: 'Загрузить',
        value: 'requiredAmount'
      },
      {
        sortable: true,
        text: 'Текущее',
        value: 'currentAmount'
      },
    ],
    items: [],
    valid: false,
    error: null,
    id: null,
    mappingStatus: null,
  }),
  computed: {
    mappingStatusType(){
      if (this.mappingStatus){
        if (this.mappingStatus.status == 'Success')
          return 'success'
        else
          return 'error'
      }

      return 'info';
    }
  },
  created (){
    this.id = this.$route.params.id;
  },
  async mounted () {
    
    this.getVendingMachine()
    this.getVendingMachineItems()
    this.getVendingMachineStatus()
  },
  methods: {
    
    getVendingMachine (){
      this.$http.get('/vendingmachines/' + this.id)
        .then(response => {
          this.machine = response.data
        })
    },
    getVendingMachineItems(){
      this.$http.get('/vendingmachines/' + this.id + '/items/loaded' )
        .then(response => {
          this.items = response.data
        })
    },
    
    getVendingMachineStatus(){
      this.$http.get('/vendingmachines/'+ this.id + '/status' ).
        then(response => {
          this.mappingStatus = response.data
        }).
        catch(e => {
          //this.mappingStatusText = e.response.data;
          console.log(e)
        })
    },
    
  }
}
</script>

<style>
.v-text-field.v-text-field--solo .v-input__control{
  min-height: 40px;
}
</style>